<template>
  <div class="main">
    <el-row :gutter="20">
      <el-col :span="4">
        <div class="side">
          <el-menu
              :default-active="$route.path"
              class="el-menu-vertical-demo overflow"
              @open="handleOpen"
              @close="handleClose"
              :router="true"
              :unique-opened="true"
              text-color="#333"
          >
            <el-menu-item index="/enterprise/centre/index/">
              <img src="~/static/enterprise/centre.png" alt class="block_inline"/>
              <span slot="title">企业中心</span>
            </el-menu-item>
            <el-menu-item index="/enterprise/centre/infomanage/">
              <img src="~/static/enterprise/info.png" alt class="block_inline"/>
              <span slot="title">信息管理</span>
            </el-menu-item>
            <!-- <el-menu-item index="/enterprise/centre/phone/">
              <img src="~/static/enterprise/pin.png" alt class="block_inline" />
              <span slot="title">招聘会</span>
            </el-menu-item>-->
            <!-- <el-menu-item index="/enterprise/centre/online/">
              <img src="~/static/center/online.png" alt class="block_inline" />
              <span slot="title">视频面试</span>
            </el-menu-item>-->
            <el-menu-item index="/enterprise/centre/myask/" v-if="location !=='湖南省'&&location !=='陕西省'">
              <img src="~/static/enterprise/ask.png" alt class="block_inline"/>
              <span slot="title">我的问答</span>
            </el-menu-item>
            <el-menu-item index="/enterprise/centre/mysection/"
                          v-if="location !=='湖南省'&&location !=='陕西省'&&location !=='甘肃省'">
              <img src="~/static/enterprise/collect.png" alt class="block_inline"/>
              <span slot="title">我的专栏</span>
            </el-menu-item>
            <el-menu-item index="/enterprise/centre/mycreate/" v-if="location =='湖南省'">
              <img src="~/static/enterprise/collect.png" alt class="block_inline"/>
              <span slot="title">我的创业</span>
            </el-menu-item>
            <el-menu-item index="/enterprise/centre/mycontest/" v-if="location =='湖南省'">
              <img src="~/static/enterprise/collect.png" alt class="block_inline"/>
              <span slot="title">我的竞赛</span>
            </el-menu-item>
            <el-menu-item index="/enterprise/centre/lanzhou/deposit" v-if="location =='甘肃省'">
              <img src="~/static/enterprise/collect.png" alt class="block_inline"/>
              <span slot="title">残保金审核</span>
            </el-menu-item>
            <el-menu-item index="/enterprise/centre/myneeds/">
              <img src="~/static/enterprise/order.png" alt class="block_inline"/>
              <span slot="title">我的需求</span>
            </el-menu-item>
            <el-submenu index="/enterprise/centre/setting/phone">
              <template slot="title">
                <img src="~/static/enterprise/setting.png" alt class="block_inline"/>
                <span slot="title">功能设置</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/enterprise/centre/setting/phone">
                  <span slot="title" class="fonts">修改手机号</span>
                </el-menu-item>
                <el-menu-item index="/enterprise/centre/email/">
                  <span slot="title" class="fonts">绑定邮箱</span>
                </el-menu-item>
                <el-menu-item index="/enterprise/centre/password/">
                  <span slot="title" class="fonts">修改密码</span>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item @click="logout">
              <img src="~/static/enterprise/exit.png" alt class="block_inline"/>
              <span slot="title">退出登录</span>
            </el-menu-item>
          </el-menu>
        </div>
      </el-col>
      <el-col :span="20" class="mains">
        <router-view/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "centre",
  data() {
    return {
      active: "1",
      location: '',
    };
  },
  computed: {
    ...mapState(['userPassword']),
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    logout() {
      this.$confirm("确定要退出当前登录吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.$store.commit("SET_USER_INFO", 1);
            this.$store.commit("SET_TOKEN", "");
            this.$store.commit("GET_USER", "");
            localStorage.removeItem("access_token");
            localStorage.removeItem("userInfo");
            localStorage.removeItem("userType");
            this.$router.push("/");
            // this.reload();
            this.$message({
              type: "warning",
              message: "退出成功!",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消退出",
            });
          });
    },
    open() {
      this.$confirm('您的密码过于简单，为了您账号安全，请尽快修改密码。', '提示', {
        confirmButtonText: '去修改',
        cancelButtonText: '',
        type: 'warning',
        showCancelButton: false,
        showClose: false,
      }).then(() => {
        this.$router.push('/enterprise/centre/password/')
      }).catch(() => {
      });
    },
  },
  created() {
    this.location = localStorage.getItem('locationSite');
  },
  mounted() {
    // if (this.userPassword == '123456') {
    //   this.open();
    // }
  }
};
</script>

<style scoped lang="less">
// @import "~assets/css/center/center.less";
.main {
  margin: 10px auto;
  width: 1200px;
  box-sizing: border-box;
}

.mains {
  margin-left: -10px;
}

.el-col-4 {
  width: 160px;
}

.side .el-submenu.is-active .el-submenu__title::after {
  content: "";
  position: absolute;
  left: 0;
  width: 6px;
  height: 100%;
  background: #00942b;
}

.el-menu {
  border-right: none;
}

.el-menu-item:after {
  transition: none;
}

.el-menu-item.is-active {
  color: #333333;
}
</style>
